import { Link } from 'react-router-dom'
import img from '../assets/images/main.svg'
import Wrapper from '../assets/wrappers/Landing'
const Landing = () => {
  const sendEmail = (e) => {
    e.preventDefault()
    window.location.href = 'mailto:alaa.desouky33@gmail.com'
  }
  return (
    <Wrapper className='full-page'>
      <div>
        <img src={img} alt="coding" />
        <h3>Site is under <span>construction</span></h3>
        <p>Meanwhile, checkout my <a target='_blank' rel='noreferrer' href='https://github.com/AlaaDesouky'>Github</a> or <Link to="#" onClick={sendEmail}>Email</Link> me.</p>
      </div>
    </Wrapper>
  )
}

export default Landing
